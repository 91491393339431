<template>
  <div
    :id="`panel-${currentItem.id}`"
    class="
      panel
      flex flex-col
      items-center
      text-lg
      md:text-2xl md:max-w-lg md:left-0 md:right-0 md:bottom-0 md:ml-auto
    "
  >
    <div
      class="
        mt-3
        mb-2
        flex
        items-center
        justify-between
        w-full
        px-6
        text-lg
        py-1
      "
    >
      <div class="w-1/4">
        <router-link
          to="/"
          title="Close Panel"
          aria-label="Close Panel"
          tabindex="2"
          role="button"
        >
          <span v-if="$store.state.showMenu">Back</span>
          <span v-else>Close</span></router-link
        >
      </div>

      <div class="w-1/4 flex justify-end items-center">
        <button
          @click="toggleFound(currentItem.id)"
          class="
            h-8
            w-8
            border-black border
            rounded-full
            flex
            items-center
            justify-center
            cursor-pointer
            hover:bg-gray-400
          "
          :aria-label="found ? 'Unmark as found' : 'Mark as found'"
          :class="{ 'border-solid bg-black hover:bg-gray-800': found }"
        >
          <svg
            v-if="!found"
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path opacity="0.8" d="M1 6.5L6 11.5L16.5 1" stroke="black" />
          </svg>
          <svg
            v-else
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 6.5L6 11.5L16.5 1" stroke="#00FF00" />
          </svg>
        </button>
      </div>
    </div>
    <div
      v-if="currentItem && currentItem.files"
      class="w-full h-full"
      :style="bg"
      :title="currentItem.title"
      role="img"
      :aria-label="`${currentItem.title}: ${currentItem.alt}`"
    ></div>
    <div
      v-if="currentItem && (currentItem.indoors === 'true' || currentItem.hint)"
      class="
        absolute
        bottom-0
        bg-white
        left-0
        right-0
        px-6
        py-3
        text-center text-sm
        leading-tight
      "
    >
      <div v-if="currentItem.hint" v-html="currentItem.hint"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  computed: {
    found() {
      return this.$store.state.foundItems.includes(this.currentItem.id)
    },
    items() {
      return this.$store.state.items
    },
    currentItem() {
      if (this.items) {
        return this.items.find((x) => x.id === this.$route.params.id)
      } else {
        return false
      }
    },
    currentIndex() {
      if (this.items) {
        return this.items.findIndex((x) => x.id === this.$route.params.id)
      } else {
        return 0
      }
    },
    bg() {
      if (this.currentItem.files.length) {
        return {
          backgroundImage: `url('${this.currentItem.files[0].bg}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      } else {
        return {
          background: 'red',
        }
      }
    },
  },
  methods: {
    toggleFound(id) {
      this.$store.dispatch('toggleFound', id)
    },
  },
}
</script>

<style lang="css">
.panel {
  @apply fixed bg-white z-40;
}

@media screen and (max-width: 768px) {
  .panel {
    @apply inset-0;
  }
}

@media screen and (min-width: 769px) {
  .panel {
    @apply right-0;
    height: 700px;
  }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  .panel {
    @apply inset-0;
    max-width: 100%;
    height: -webkit-fill-available;
    margin: 0;
  }
}

.images img {
  @apply h-full object-cover;
  height: 100% !important;
}
</style>
